
function formatTime(date) {
	var year = date.getFullYear();
	var month = date.getMonth() + 1;
	var day = date.getDate();

	var hour = date.getHours();
	var minute = date.getMinutes();
	var second = date.getSeconds();


	return [year, month, day].map(formatNumber).join('-') + ' ' + [hour, minute, second].map(formatNumber).join(':')
}

function formatDate(date) {
	if(!date){
		date = new Date()
	}
	var year = date.getFullYear();
	var month = date.getMonth() + 1;
	var day = date.getDate();


	return [year, month, day].map(formatNumber).join('-')
}

function formatNumber(n) {
	n = n.toString();
	return n[1] ? n : '0' + n
}

const getParentAreaByValue = (list,id) => {
	for (let i in list) {
		if (list[i].value === id) {

			//查询到就返回该数组对象
			return [list[i]]
		}

		if (list[i].children) {

			let node = getParentAreaByValue(list[i].children, id)
			if (node !== undefined) {
				//查询到把父节点连起来
				return node.concat(list[i])
			}
		}
	}
}

export default {
    formatTime,
    formatDate,
	getParentAreaByValue
}